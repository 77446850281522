<template>
  <b-row>
    <b-col cols="3">
      <TreeView :setDong="setDong" :selected="dongSearch == '' ? '전체' : dongSearch"></TreeView>
    </b-col>
    <b-col cols="9">
      <b-card no-body class="mb-4">
        <b-card-body class="p-3">
          <h5 class="card-title mb-0">주민투표 작성</h5>
        </b-card-body>
        <div class="p-3 align-items-center">
        
          <b-form>  
            <b-row>
              <b-col cols="12" lg="2">
                <b-form-group>
                  <b-form-select v-model="selected" :options="selectOptions"></b-form-select>
                </b-form-group>
              </b-col>

              <b-col cols="2" lg="3">
                <b-form-group id="input3">
                <b-form-datepicker id="example-datepicker" v-model="value" class="mb-2" placeholder="투표 마감 기한"></b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" lg="8">
                <b-form-group id="input2">
                  <b-form-input type="text" id="input2" placeholder="제목을 입력해 주세요."
                  ></b-form-input>
                </b-form-group>
              </b-col>
              
              <b-col cols="12" lg="12">
                <froala :tag="'textarea'" :config="config" v-model="content"></froala>
                <br/>
              </b-col>

              <b-col cols="2">
                <b-form-group
                  label="설문 방식"
                >
                  <b-form-select v-model="selected2" :options="select2Options" @change="(voteOptionsCnt = 2)"></b-form-select>
                </b-form-group>
              </b-col>

              <b-col cols="2" v-if="(selected2 == 2 || selected2 == 3)">
                <b-form-group
                id="input2"
                label="항목 수"
              >
                <b-form-input type="number" id="input2" size="md" v-model="voteOptionsCnt" min="2" max="10"
                ></b-form-input>
              </b-form-group>
              </b-col>

              <b-col cols="12" v-if="(selected2 == 1 || selected2 == 2)">
                <b-form-group v-slot="{ ariaDescribedby }">
                  <div v-for="i in Array(Number(voteOptionsCnt)).keys()" v-bind:key="i">
                  <b-form-radio :aria-describedby="ariaDescribedby" name="some-radios" :value="i">
                    <b-form-input type="text" id="input2" style="marginTop: -4px;"                placeholder="항목 내용"
                  ></b-form-input>
                  </b-form-radio>
                  <br v-if="(i != voteOptionsCnt - 1)"/>
                  </div>
                </b-form-group>
              </b-col>

              <b-col cols="12" v-if="(selected2 == 3)">
                <b-form-group v-slot="{ ariaDescribedby }">
                  <div v-for="i in Array(Number(voteOptionsCnt)).keys()" v-bind:key="i">
                  <b-form-checkbox :aria-describedby="ariaDescribedby" name="some-radios" :value="i">
                    <b-form-input type="text" id="input2" style="marginTop: -4px;"                placeholder="항목 내용"
                  ></b-form-input>
                  </b-form-checkbox>
                  <br v-if="(i != voteOptionsCnt - 1)"/>
                  </div>
                </b-form-group>
              </b-col>  
            </b-row>

            <div class="d-flex justify-content-end">
              <b-button
                type="button"
                variant="outline-primary"
                class="d-flex align-items-center px-3"
              >등록</b-button>
            </div>
          </b-form>
        </div>
      </b-card>
    </b-col>
  </b-row>
  
</template>

<script>
import { dongList } from '../api';
import TreeView from '../components/apps/TreeView';
export default {
  name: "VoteWrite",

  data: () => ({
    selected: null,
    selected2: 1,
    voteOptionsCnt: 2,
    selectOptions: [
                { value: null, text: '투표 참여 범위', disabled: true },
                { value: 'all', text: '전체' },
                
    ],
    select2Options: [
                { value: '1', text: '찬성/반대' },
                { value: '2', text: '1개 선택' },
                { value: '3', text: '복수 선택' }
    ],
    content: "",
    dongSearch: "",
    config: {
          
      }

    
  }),
  components: {TreeView},
  methods: {
    setDong(dong, isChild){
      if(isChild){
        this.dongSearch = dong;
      }
      else{
        this.dongSearch = "";
      }
    },
  },
  async created() {
        let dongListRes = await dongList();
        if(dongListRes.error == true){
            return;
        }
        for await(let dongListResItem of dongListRes.dongList){
            this.selectOptions.push({value: dongListResItem.name, text: dongListResItem.name});
        }
    },
};
</script>
